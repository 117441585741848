'use client';

import { FC, useState } from 'react';
import { IconClose } from '@maverick/icons/dist/IconClose';
import { IconError } from '@maverick/icons/dist/IconError';
import { IconSuccess } from '@maverick/icons/dist/IconSuccess';
import { useTimeout } from '../../../shared/hooks/useTimeout';
import * as Styled from './Toast.styled';
import { UtilsCalculateDisplayTime } from '@maverick/utils';
import { Icon } from '../Icon';

enum Variants {
	success = 'success',
	error = 'error',
}

export interface ToastProps {
	variant: keyof typeof Variants;
	icon?: JSX.Element;
	text: string;
	link?: { link: string; label: string };
	secondary?: string;
	onClose?: () => void;
}
export const Toast: FC<ToastProps> = ({ variant, icon, text, link, secondary, onClose }) => {
	const [isClosing, setIsClosing] = useState<boolean>(false);
	const toastTimeout = UtilsCalculateDisplayTime(text);

	useTimeout(() => {
		handleClose();
	}, toastTimeout);

	const handleClose = () => {
		setIsClosing(true);
		setTimeout(() => {
			onClose?.();
		}, 300);
	};

	let _icon: JSX.Element;
	if (icon) {
		_icon = icon;
	} else {
		if (variant === 'success') _icon = IconSuccess;
		else _icon = IconError;
	}

	return (
		<Styled.Container role='alert' variant={variant} isClosing={isClosing} data-testid='toast'>
			<Styled.Icon data-testid='toast-icon'>
				<Icon icon={_icon} customSize />
			</Styled.Icon>

			<Styled.Message data-testid='text'>
				<Styled.Text>{text}</Styled.Text>{' '}
				<Styled.Link href={link?.link} target='_blank'>
					{link?.label}
				</Styled.Link>{' '}
				<Styled.Text>{secondary}</Styled.Text>
			</Styled.Message>

			<Styled.Close onClick={handleClose}>
				<Icon icon={IconClose} customSize />
			</Styled.Close>
		</Styled.Container>
	);
};
