'use client';
import { useRef } from 'react';
import { Provider } from 'react-redux';
import { makeStore, persistor, RootStore, store } from '../lib/store/Store';
import { PersistGate } from 'redux-persist/integration/react';

const basePath = process.env.AUTH0_BASE_URL;

export default function StoreProvider({ children }: { children: React.ReactNode }) {
	const storeRef = useRef<RootStore>();

	if (!storeRef.current) {
		storeRef.current = makeStore();
	}

	const retrieveToken = async () => {
		try {
			const res = await fetch(`${basePath}/api/session`);
			if (res && res.ok) {
				const session = await res.json();
				store.dispatch({ type: 'app/setAuth0Token', payload: session.accessToken });
				return session.accessToken;
			}
		} catch (error) {}
	};

	const loadingPlaceholder = (
		<html>
			<body></body>
		</html>
	);

	const onBeforeLift = async () => {
		const userInfo = store.getState().app.userInfo;
		const auth0Token = store.getState().app.auth0Token;
		if (userInfo !== null && auth0Token === null) {
			await retrieveToken();
		}
	};

	return (
		<Provider store={storeRef.current}>
			<PersistGate loading={loadingPlaceholder} persistor={persistor} onBeforeLift={onBeforeLift}>
				{children}
			</PersistGate>
		</Provider>
	);
}
