import styled, { css } from 'styled-components';

export const Container = styled.div<{ variant: string; isClosing: boolean }>`
	padding: 16px 16px;
	color: #ffffff;
	display: flex;
	align-items: center;
	position: relative;
	width: 90%;
	margin: 0 auto;
	translate: 0 70px;
	animation-name: enter;
	animation-duration: 0.3s;
	animation-fill-mode: forwards;
	opacity: 1;
	transition: opacity ease 0.3s;

	${({ variant }) =>
		variant === 'success' &&
		css`
			background: #2d8633;
		`}

	${({ variant }) =>
		variant === 'error' &&
		css`
			background: #b00020;
		`}

    ${({ isClosing }) =>
		isClosing &&
		css`
			opacity: 0;
		`}

    &:not(:first-child) {
		margin-top: 8px;
	}

	@keyframes enter {
		0% {
			translate: 0 70px;
		}

		100% {
			translate: 0 0;
		}
	}
`;

export const Icon = styled.div`
	min-width: 24px;
	min-height: 24px;
	width: 32px;
	height: 32px;
	margin: 0 16px 0 0;
`;

export const Message = styled.div``;

export const Text = styled.span``;

export const Link = styled.a`
	font-weight: 600;

	:link,
	:visited {
		text-decoration: underline;
	}
`;

export const Close = styled.div`
	width: 16px;
	height: 16px;
	position: absolute;
	top: 8px;
	right: 4px;
	cursor: pointer;
`;
