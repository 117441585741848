'use client';

import { createContext, FC, useState, ReactNode } from 'react';
import { Toast, ToastProps } from './Toast';

type ToastContextType = {
	setToast: (toast: ToastProps) => void;
};

interface ToastItem extends ToastProps {
	id: string;
}

interface ToastProviderProps {
	children: ReactNode;
}

export const ToastContext = createContext<ToastContextType>({ setToast: () => {} });

export const ToastProvider: FC<ToastProviderProps> = ({ children }) => {
	const [toasts, setToasts] = useState<Array<ToastItem>>([]);

	const setToast = (toast: ToastProps) => {
		const item: ToastItem = { ...toast, id: String(Math.random()) };
		setToasts((prev) => [...prev, item]);
	};

	const handleOnClose = (id: string) => {
		setToasts((prev) => {
			const indexToRemove = prev.findIndex((t) => t.id === id);
			if (indexToRemove >= 0) prev.splice(indexToRemove, 1);
			return [...prev];
		});
	};

	return (
		<ToastContext.Provider value={{ setToast: setToast }}>
			{children}

			<div style={{ position: 'fixed', bottom: 72, width: '100%', zIndex: 1003 }}>
				{toasts.map((t) => (
					<Toast {...t} key={t.id} onClose={() => handleOnClose(t.id)} />
				))}
			</div>
		</ToastContext.Provider>
	);
};
