import { Action, combineReducers, configureStore, Middleware } from '@reduxjs/toolkit';
import { createTransform, PERSIST, persistReducer, persistStore, PURGE } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import traverse from 'traverse';
import { createWrapper } from 'next-redux-wrapper';
import { createStateSyncMiddleware } from 'redux-state-sync';
import { appSlice, AppState } from './slices/App.slice';
import thunk, { ThunkAction } from 'redux-thunk';

const dateTransform = createTransform(null, (outboundState) => {
	return traverse(outboundState).map((val) => {
		const d = new Date(val);
		if (d instanceof Date && !isNaN(d as unknown as number) && d.toISOString() === val) return d;
		return val;
	});
});

const getPersistConfig = (key: string, blacklist?: Array<string>) => ({
	key,
	storage,
	blacklist,
	transforms: [dateTransform],
});

const reducers = combineReducers({
	app: persistReducer<AppState>(getPersistConfig(appSlice.name, ['loading', 'auth0Token']), appSlice.reducer),
});

const middlewares: Array<Middleware> = [thunk];
const isServer = typeof window === 'undefined';
if (!isServer) {
	middlewares.push(createStateSyncMiddleware({ blacklist: [PERSIST, PURGE] }));
}

export const store = configureStore({
	reducer: reducers,
	devTools: true,
	middleware: middlewares,
});

export const makeStore = () => store;

export const persistor = persistStore(store);

export type RootStore = ReturnType<typeof makeStore>;
export type RootState = ReturnType<RootStore['getState']>;
export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, RootState, unknown, Action>;
export const StoreWrapper = createWrapper<RootStore>(makeStore);
